import * as React from 'react';
import { useLocation } from 'react-router';
import Header from '../../Header';

export default function CaptainInterestThankYou() {
  return (
    <div className="mx-3 md:mx-auto md:max-w-2xl pt-2 md:pt-12">
      <Header />
      <div className="mt-6 p-6 bg-white border shadow-lg rounded-lg">
        <h2 className="font-bold">Thank you</h2>
        <p>
          Terrific. We’ll send you an email with instructions on what to do
          next: you’ll give us the location for your walk so that we can create
          a personalized link for you to recruit people to walk with you. (Check
          your Spam or other junk folders for these instructions!)
        </p>

        <p>
          If you want to get started organizing right away, then you can see the
          full
          <a href="https://wewalkthevote.com/instructions" target="_blank">
            field guide to being a #walkthevote captain here
          </a>
          .
        </p>

        <p>
          To participate and vote in a #walkthevote, you and your fellow walkers
          need to be registered and have an absentee ballot in hand. The rules
          vary from state to state, so we've included links to learn more.
        </p>

        <p>
          <span className="font-bold">Are you registered to vote?</span> Please
          register to vote{' '}
          <a
            href="https://www.voteamerica.com/register-to-vote/"
            target="_blank"
          >
            here
          </a>
          .
        </p>

        <p>
          <span className="font-bold">Need to check if you're registered?</span>{' '}
          You can do that{' '}
          <a
            href="https://www.voteamerica.com/am-i-registered-to-vote/"
            target="_blank"
          >
            here
          </a>
          .
        </p>

        <p>
          <span className="font-bold">
            Have you requested an absentee ballot?
          </span>{' '}
          You can do so{' '}
          <a
            href="https://www.voteamerica.com/absentee-ballot/"
            target="_blank"
          >
            here
          </a>
          .
        </p>

        <p className="py-3 leading-7 italic">
          If you want your neighbors, or friends in other places, to
          #walkthevote, please tell them you're participating and send them a
          link to this site.
        </p>
      </div>
    </div>
  );
}
