import * as React from 'react';
import { useLocation } from 'react-router';
import { Button } from '../../FormComponents';
import Header from '../../Header';
import useQuery from '../../hooks/useQuery';
import { Event, lookupMobilizeEvents } from '../../Mobilize';

function MobilizeList({ events }: { events: Event[] }) {
  const turnWalkerIntoCaptain = React.useCallback(() => {}, []);
  if (!events) {
    return <p>Loading...</p>;
  }
  if (events.length === 0) {
    return (
      <>
        <p>
          It looks like we’re still waiting for someone to lead a #walkthevote
          in your area. We will email you when there is.
        </p>
      </>
    );
  }
  if (events.length) {
    return (
      <>
        <p>
          There are #walkthevotes in your area! Check out the following below
        </p>
        <ul>
          {events.map((event) => (
            <li key={event.id}>
              <a
                target="_blank"
                href={`https://www.mobilize.us/walkthevote/event/${event.id}/`}
              >
                {event.title}
              </a>
            </li>
          ))}
        </ul>
      </>
    );
  }
}

export default function WalkerInterestThankYou() {
  const qs = useQuery();
  const zip = qs.get('zip');

  const [mobilizeEvents, setMobilizeEvents] = React.useState<Event[] | null>(
    null
  );

  const loadMobilizeEvents = React.useCallback(async () => {
    const events = await lookupMobilizeEvents(zip);
    setMobilizeEvents(events);
  }, [zip, setMobilizeEvents]);

  React.useEffect(() => {
    loadMobilizeEvents();
  }, []);

  return (
    <div className="mx-3 md:mx-auto md:max-w-2xl pt-2 md:pt-12">
      <Header />
      <div className="mt-6 p-6 bg-white border shadow-lg rounded-lg">
        <h2 className="font-bold">Thank you</h2>

        <MobilizeList events={mobilizeEvents} />

        <p>
          To participate and vote in a #walkthevote, you and your fellow walkers
          need to be registered and have an absentee ballot in hand. The rules
          vary from state to state, so we've included links to learn more.
        </p>

        <p>
          <span className="font-bold">Are you registered to vote?</span> Please
          register to vote{' '}
          <a
            href="https://www.voteamerica.com/register-to-vote/"
            target="_blank"
          >
            here
          </a>
          .
        </p>

        <p>
          <span className="font-bold">Need to check if you're registered?</span>{' '}
          You can do that{' '}
          <a
            href="https://www.voteamerica.com/am-i-registered-to-vote/"
            target="_blank"
          >
            here
          </a>
          .
        </p>

        <p>
          <span className="font-bold">
            Have you requested an absentee ballot?
          </span>{' '}
          You can do so{' '}
          <a
            href="https://www.voteamerica.com/absentee-ballot/"
            target="_blank"
          >
            here
          </a>
          .
        </p>

        <p className="py-3 leading-7 italic">
          If you want your neighbors, or friends in other places, to
          #walkthevote, please tell them you're participating and send them a
          link to this site.
        </p>
      </div>
    </div>
  );
}
